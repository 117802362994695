import {
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";

import React, { Suspense, useEffect } from "react";
import Main from "../components/Main";
import { MACHINE_TYPE } from "../assets/constants/constants";

const Navigation = () => {
  
  

  const Login = React.lazy(() => import("../components/Login/Login"));

  const Dashboardultimate = React.lazy(
    () => import("../components/Ultimate/Ultimate")
  );
  const Dashboard = React.lazy(
    () => import("../components/Dashboard/Dashboard")
  );
  const Dashboardaverage = React.lazy(
    () => import("../components/Average/Average")
  );
  const SelectStation = React.lazy(
    () => import("../components/Login/SelectStation")
  );

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      location.pathname !== "/login" &&
      !location.pathname.startsWith("/select-station/")
    ) {
      if (checkLocalStorage()) {
        let previousDashboardType = localStorage.getItem("machineType");
        const selectedSitedId = localStorage.getItem("site_id");
        const companyId = localStorage.getItem("companyId");

        const machine_id = localStorage.getItem("machine_id");
        const outdoorSensorId = localStorage.getItem("outdoorSensorId");

        if (previousDashboardType === MACHINE_TYPE.ULTIMATE)
          navigate(
            `/dashboard-ultimate/${selectedSitedId}/${companyId}/${machine_id}/${outdoorSensorId}`
          );
        else if (previousDashboardType === MACHINE_TYPE.VANILA)
          navigate(
            `/dashboard-vanilla/${selectedSitedId}/${companyId}/${machine_id}/${outdoorSensorId}`
          );
        else if (previousDashboardType === MACHINE_TYPE.ULTRAPRO)
          navigate(
            `/dashboard-ultrapro/${selectedSitedId}/${companyId}/${machine_id}/${outdoorSensorId}`
          );
      }
    }
  }, [location.pathname]);
  const checkLocalStorage = () => {
    const requiredKeys = [
      "companyId",
      "site_id",
      "machine_id",
      "outdoorSensorId",
      "machineType",
    ];
    return requiredKeys.every((key) => localStorage.getItem(key) !== null);
  };

  return (
    <>
      <div id="main-wraper">
        <Routes>
          <Route
            path="/login"
            element={
              <Suspense fallback={<></>}>
                <Login />
              </Suspense>
            }
          />
          <Route
            path="/"
            element={
              <Suspense fallback={<></>}>
                <Main />
              </Suspense>
            }
          >
            <Route
              path="/dashboard-ultrapro/:site_id/:company_id/:machine_id/:outdoorSensorId"
              element={
                <Suspense fallback={<></>}>
                  <Dashboard />
                </Suspense>
              }
            />
            <Route
              path="/select-station/:company_id"
              element={
                <Suspense fallback={<></>}>
                  <SelectStation />
                </Suspense>
              }
            />
            <Route
              path="/dashboard-ultimate/:site_id/:company_id/:machine_id/:outdoorSensorId"
              element={
                <Suspense fallback={<></>}>
                  <Dashboardaverage />
                </Suspense>
              }
            />
            <Route
              path="/dashboard-vanilla/:site_id/:company_id/:machine_id/:outdoorSensorId"
              element={
                <Suspense fallback={<></>}>
                  <Dashboardultimate />
                </Suspense>
              }
            />
          </Route>
        </Routes>
      </div>
    </>
  );
};

export default Navigation;
