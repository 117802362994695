import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import WebService from "../Services/WebService";
let version = "1.0.25";
const Main = () => {
  useEffect(() => {
    setInterval(() => {
      getLatestVersion();
    }, 60000);
  }, []);

  const getLatestVersion = () => {
    return WebService.getAPI({
      action: `dashboard-version`,
      body: null,
    })
      .then((res: any) => {
        if (res.result.version !== version) {
          window.location.reload();
        }
      })
      .catch((error: any) => {
        console.log("error", error);
        return null;
      });
  };

  return (
    <>
      {/* <Header /> */}
      <div id="main-app">
        {/* <VerticalMenu /> */}
        <div className="app-body kpi-dahboard-page">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Main;
